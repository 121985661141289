import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Link } from 'react-router-dom';
import styled from "styled-components";

import { mobile } from "../responsive";


const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color:	#000000;
  // padding: 20px; 
  width: 100%; 
     
  ${mobile({ padding: "0px", width: "430px" })}
`;

const FooterContent = styled.div`
  text-align: center;
  width: 100%;
`;

const Logo = styled.h4`
  color: white;
  margin-bottom: 24px;
`;

const Links = styled.div`
  margin-bottom: 20px;
  a {
    color: white;
    font-size: 12px;
    margin: 0 10px;
    text-decoration: none;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #4EC8ED;
      // text-decoration: underline;
      text-decoration: none;
    }
  }
  .separator {
    display: inline-block; 
    color: #4EC8ED;
    margin: 0 2px; 
    border-left: 5px solid 
    height: 1.5em;
    position: relative;
    top: -0.2em; 

  }
`;

const Copyright = styled.p`
  color: white;
  font-size: 12px;
  font-style: italic;
  margin: 30px 20px 30px;
  text-align: left;
  ${mobile({
  textAlign: "center",
  margin: "32px 0px"
})}
`;

const SocialMediaIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

  .icon {
    color: white;
    margin: 0 10px;
    transition: color 0.3s ease;

    &:hover {
      color: #4EC8ED;
    }
  }

  ${mobile({ flexDirection: "row" })}
`;


const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <FooterContainer>
      <FooterContent>
        <Logo>WHY SHOP WITH US?</Logo>
        <Links>
          <Link to="/policy"> Privacy Policy </Link>
          <span className="separator">|</span>
          <span style={{ color: "#f0f0f0" }}>|</span>
          <Link to="/shipping_matrix"> Shipping Matrix </Link>
          <span className="separator">|</span>
          <span style={{ color: "#f0f0f0" }}>|</span>
          <a href="/my_profile/my_orders"> Order History </a>
          <span className="separator">|</span>
          <span style={{ color: "#f0f0f0" }}>|</span>
          <a href="https://lusuku.shop/vendorRegistration" target="_blank" rel="noopener noreferrer"> Become a Vendor </a>
          <span className="separator">|</span>
          <span style={{ color: "#f0f0f0" }}>|</span>
          <Link to="/payment_options"> Payment Options & Terms</Link>
          <span className="separator">|</span>
          <span style={{ color: "#f0f0f0" }}>|</span>
          <a href="https://lusuku.shop/" target="_blank" rel="noopener noreferrer">Vendor Dashboard</a>
        </Links>
        <SocialMediaIcons>
          <a href="https://www.facebook.com/lusuku.ug" target="_blank" rel="noopener noreferrer">
            <FacebookIcon className="icon" />
          </a>
          <a href="https://www.instagram.com/lusukuonline/" target="_blank" rel="noopener noreferrer">
            <InstagramIcon className="icon" />
          </a>
          <a href="https://twitter.com/lusukuonline" target="_blank" rel="noopener noreferrer">
            <TwitterIcon className="icon" />
          </a>
          <a href="https://www.linkedin.com/company/lusuku" target="_blank" rel="noopener noreferrer">
            <LinkedInIcon className="icon" />
          </a>
          <a href="https://wa.me/256700771301" target="_blank" rel="noopener noreferrer">
            <WhatsAppIcon className="icon" />
          </a>
        </SocialMediaIcons>
        <Copyright>© All Rights Reserved {currentYear} ~ Lusuku </Copyright>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
