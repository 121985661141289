import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, Table, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Divider, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Image } from 'react-bootstrap';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchShippingCost } from '../redux/shippingCostRedux';
import { createOrder, payAirtelMoneyCheckout, payCardCheckout, payMoMoCheckout } from '../redux/restaurantOrderActions';
import CreditCardForm from '../components/CreditCardForm/CreditCardForm';
import airtel from '../assets/img/airtel_money.png';
import momo from '../assets/img/momo.jpg';
import { clearCart } from '../redux/restaurantCartSlice';
import RestaurantNavBar from '../components/RestaurantNavBar';

const Container = styled.div`
  padding: 20px;
  padding-top: 20px; /* Adjust to accommodate navbar height */

  @media (max-width: 768px) {
    padding-top: 165px; /* Adjust for mobile for the vendor banner */
    padding: 10px;
  }

  @media (max-width: 480px) {
    margin-top: 70px; 
    padding-top: 60px; 
    padding-bottom: 20px;
  }
`;

const PayCardButton = styled.button`
  border: none;
  padding: 15px 20px;
  background-color: teal;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 4px;
  &:disabled {
    background-color: #ccc;
    color: #aaa;
    font-weight: 300;
    font-size: 14px;
    cursor: not-allowed;
  }
`;

const AirtelPayButton = styled.button`
  border: none;
  padding: 7px 15px;
  background-color: #f00;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  &:disabled {
    background-color: #ccc;
    color: #aaa;
    font-weight: 300;
    font-size: 14px;
    cursor: not-allowed;
  }
`;

const useStyles = makeStyles((theme) => ({
    input: {
        "& input[type=number]": {
            "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
            paddingBottom: "5px",
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
    },
    inputGreen: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "green",
            },
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "green",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "green",
        },
        "& .MuiOutlinedInput-input": {
            color: "green",
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "green",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "green",
        },
    },
    tableHeader: {
        backgroundColor: "#506266",
        color: "#ffffff",
        borderRadius: "3px",
    },
    tableHeaderCenter: {
        backgroundColor: "#506266",
        color: "#ffffff",
        borderRadius: "3px",
        textAlign: "center",
    },
    responsiveFont: {
        fontSize: 18,
        color: '#000B0D',
        fontFamily: 'Poppins, sans-serif',

        [theme.breakpoints.up('sm')]: {
            fontSize: 18,
        },

        [theme.breakpoints.up('md')]: {
            fontSize: 20,
        },

        [theme.breakpoints.up('lg')]: {
            fontSize: 24,
        },
    },
    responsiveImage: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            maxWidth: 600,
        },
    },
    responsiveCard: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            maxWidth: 600,
        },
    },
    responsiveTable: {
        width: '100%',
        overflowX: 'auto',
        '& table': {
            minWidth: 500,
            width: '100%',
            [theme.breakpoints.up('md')]: {
                minWidth: 700,
            },
        },
    },
}));

const StyledTextField = styled(TextField)`
  & .MuiInputBase-root {
    padding: 10px; /* Add padding to ensure proper alignment */
  }
  & .MuiOutlinedInput-input {
    padding: 12px; /* Adjust padding to align placeholder */
  }
`;

const validationSchema = Yup.object().shape({
    cardNumber: Yup.string().required('Card Number is required.'),
    expiry_month: Yup.string().required('Expiry Month is required.'),
    expiry_year: Yup.string().required('Expiry Year is required.'),
    cardCvv: Yup.string().required('CVV is required.'),
    cardType: Yup.string().required('Card Type is required.'),
    fullName: Yup.string().required('Full Name is required.'),
});

const RestaurantPlaceOrderScreen = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const { shippingAddress, paymentMethod, total } = useSelector(
        (state) => state.restaurantCart
    );
    const currentRestaurant = useSelector(
        (state) => state.restaurantCart.currentRestaurant
    );

    const cart = useSelector(
        (state) => state.restaurantCart.carts[currentRestaurant] || {}
    );

    const products = Object.values(cart);

    const user = useSelector((state) => state.user.currentUser);

    const { orderCreationStatus, paymentCreationStatus } = useSelector(
        (state) => state.restaurantOrder
    );

    const [orderSource] = useState('Web');

    useEffect(() => {
        if (
            Object.keys(shippingAddress).length === 0 ||
            Object.keys(paymentMethod).length === 0 ||
            Object.keys(products).length === 0
        ) {
            history.push(`/`);
        }
    }, [shippingAddress, paymentMethod, products, history, currentRestaurant]);

    const { shippingCost } = useSelector((state) => state.shippingCosts);

    const serverShippingFees = shippingCost.map((cost) => cost.cost);
    const serverShippingFeesID = shippingCost.map((cost) => cost._id);

    const totalOrderCostPlusShipping = Number(total) + Number(serverShippingFees);

    useEffect(() => {
        if (paymentMethod === 'MOMO' && orderCreationStatus === 'fulfilled') {
            history.push('/momoOrderPage');
        } else if (paymentMethod === 'COD' && orderCreationStatus === 'fulfilled') {
            history.push('/orderPage');
        }
    }, [orderCreationStatus, paymentMethod, history]);

    const formik = useFormik({
        initialValues: {
            cardNumber: '',
            expiry_month: '',
            expiry_year: '',
            cardCvv: '',
            cardType: '',
            fullName: '',
        },
        validationSchema,
        onSubmit: (values) => {
            cardPlaceOrderHandler(values);
        },
    });

    const [postData, setPostData] = useState({
        mobile: '',
    });

    const userId = user?.result?._id;
    useEffect(() => {
        if (!userId) {
            history.push('/login');
        }
    }, [userId, history]);

    useEffect(() => {
        dispatch(fetchShippingCost());
    }, [dispatch]);

    const placeOrderHandler = (e) => {
        e.preventDefault();
        dispatch(
            createOrder({
                orderItems: products?.map((item) => ({
                    ...item,
                    vendorId: item.vendorId,
                })),
                shippingAddress,
                shippingCost: serverShippingFees[0],
                shippingCost_id: serverShippingFeesID[0],
                paymentMethod,
                itemsPrice: total,
                totalPrice: totalOrderCostPlusShipping,
                customerName: user?.result?.name,
                client_id: user?.result?._id,
                orderSrc: orderSource,
                vendorId: currentRestaurant,
                qty: products?.map((item) => item.quantity),
                image: products?.map((item) => item.image),
            })
        ).then(() => {
            dispatch(clearCart(currentRestaurant));
        });
    };

    const payMoMoCheckoutHandler = (e) => {
        e.preventDefault();
        const orderItems = products.map((item) => ({
            name: item.name,
            vendorId: item.restaurantId,
            qty: item.quantity,
            price: item.price,
            image: item.image,
        }));

        dispatch(
            payMoMoCheckout({
                mobile: postData.mobile,
                totalPrice: totalOrderCostPlusShipping,
                client_id: user?.result?._id,
                orderItems, // Include orderItems in the payload
            })
        );

        dispatch(
            createOrder({
                orderItems,
                shippingAddress,
                shippingCost: serverShippingFees[0],
                shippingCost_id: serverShippingFeesID[0],
                paymentMethod,
                itemsPrice: total,
                totalPrice: totalOrderCostPlusShipping,
                customerName: user?.result?.name,
                client_id: userId,
                orderSrc: orderSource,
            })
        );
    };

    const generateTxRef = (clientId) => {
        return clientId + '-' + Date.now();
    };

    const payAirtelMoneyCheckoutHandler = (e) => {
        e.preventDefault();
        const uniqueTxRef = generateTxRef(user.result._id);
        const orderItems = products.map((item) => ({
            name: item.name,
            vendorId: item.restaurantId,
            qty: item.quantity,
            price: item.price,
            image: item.image,
        }));

        dispatch(
            payAirtelMoneyCheckout({
                mobile: postData.mobile,
                totalPrice: totalOrderCostPlusShipping,
                client_id: user?.result?._id,
                email: user?.result?.email,
                uniqueTxRef,
                network: paymentMethod,
                redirect_url: `${window.location.origin}/airtelPay`,
                orderItems,
            })
        ).then((action) => {
            if (!action.error && action.payload.data.meta.authorization) {
                window.location.href = action.payload.redirectUrl;
            }
        });

        dispatch(
            createOrder({
                orderItems: products,
                shippingAddress,
                shippingCost: serverShippingFees[0],
                paymentMethod,
                itemsPrice: total,
                totalPrice: totalOrderCostPlusShipping,
                customerName: user?.result?.name,
                client_id: userId,
                orderSrc: orderSource,
            })
        );
    };

    const cardPlaceOrderHandler = () => {
        const cardDetails = {
            cardNumber: formik.values.cardNumber,
            expiry_month: formik.values.expiry_month,
            expiry_year: formik.values.expiry_year,
            cardCvv: formik.values.cardCvv,
            cardType: formik.values.cardType,
            fullName: formik.values.fullName,
        };

        dispatch(
            payCardCheckout({
                cardDetails,
                totalPrice: totalOrderCostPlusShipping,
                client_id: user?.result?._id,
                customer_name: user?.result?.name,
                email: user?.result?.email,
                network: paymentMethod,
                redirect_url: `${window.location.origin}/cardOrder`,
            })
        )
            .then((action) => {
                if (action.payload.redirectUrl) {
                    window.location.href = action.payload.redirectUrl;
                }
            })
            .catch((err) => {
                console.error('Error while dispatching payCardCheckout:', err);
            });

        dispatch(
            createOrder({
                orderItems: products,
                shippingAddress,
                shippingCost: serverShippingFees[0],
                paymentMethod,
                itemsPrice: total,
                totalPrice: totalOrderCostPlusShipping,
                customerName: user?.result?.name,
                client_id: userId,
                orderSrc: orderSource,
            })
        );
    };

    let airtelButtonText;
    switch (true) {
        case paymentCreationStatus === 'processing payment':
            airtelButtonText = 'Processing Payment...';
            break;
        case paymentCreationStatus === 'fulfilled':
            airtelButtonText = 'Payment Initiated!';
            break;
        case paymentCreationStatus === 'rejected':
            airtelButtonText = 'Payment attempt Failed, try again!';
            break;
        case orderCreationStatus === 'pending':
            airtelButtonText = 'Creating Order...';
            break;
        case orderCreationStatus === 'fulfilled':
            airtelButtonText = 'Order Created...';
            break;
        default:
            airtelButtonText = 'Proceed to Pay';
    }

    return (
        <>
            <RestaurantNavBar />
            <Container maxWidth="100%" style={{ marginTop: '70px', paddingTop: '60px', paddingBottom: '20px' }}>
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography
                                    className={classes.responsiveFont}
                                    sx={{
                                        fontSize: { xs: 14, sm: 16, md: 18, lg: 20 },
                                        fontStyle: 'italic',
                                        paddingTop: { xs: 5, sm: 3, md: 3 },
                                    }}
                                    gutterBottom
                                >
                                    SHIPPING ADDRESS
                                </Typography>
                            </Grid>
                            <Divider style={{ paddingTop: '2px' }} />
                            <Grid item>
                                <Typography variant="body" sx={{ fontSize: 13 }} color="text.secondary">
                                    <b>Customer Contact:</b>{' '}
                                    <span style={{ fontStyle: 'italic', fontSize: '12px', color: '#00668bc4' }}>
                                        {shippingAddress.customerNumber && Object.keys(shippingAddress.customerNumber).length !== 0
                                            ? shippingAddress.customerNumber
                                            : 'Not available'}
                                    </span>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body" sx={{ fontSize: 13 }} color="text.secondary">
                                    <b>Delivery Zone:</b>{' '}
                                    <span style={{ fontStyle: 'italic', fontSize: '12px', color: '#00668bc4' }}>
                                        {shippingAddress.locationName && Object.keys(shippingAddress.locationName).length !== 0
                                            ? shippingAddress.locationName.vicinity
                                            : 'Not available'}
                                    </span>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body" sx={{ fontSize: 13 }} color="text.secondary">
                                    <b>Payment Method:</b>{' '}
                                    <span style={{ fontStyle: 'italic', fontSize: '12px', color: '#b63700' }}>
                                        {paymentMethod && Object.keys(paymentMethod).length !== 0 ? paymentMethod : 'Not available'}
                                    </span>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Divider style={{ paddingTop: '20px', marginBottom: '15px' }} />
                        <Typography
                            component="h2"
                            variant="h6"
                            style={{ color: '#026773', fontSize: '18px', fontStyle: 'italic' }}
                            gutterBottom
                        >
                            ORDER ITEMS SUMMARY!
                        </Typography>
                        <div className={classes.responsiveTable}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHeader}>Image</TableCell>
                                        <TableCell className={classes.tableHeader}>Product Name</TableCell>
                                        <TableCell className={classes.tableHeaderCenter}>Qty</TableCell>
                                        <TableCell className={classes.tableHeaderCenter}>Price</TableCell>
                                        <TableCell className={classes.tableHeaderCenter}>Sum</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {products.map((item) => {
                                        const optionsTotal = item.options.reduce((sum, option) => sum + option.price, 0);
                                        const itemTotal = (item.price + optionsTotal) * item.quantity; // Calculate the sum for the row
                                        return (
                                            <TableRow key={item?._id} hover={true}>
                                                <TableCell>
                                                    <Image
                                                        className={classes.responsiveImage}
                                                        style={{
                                                            width: '100px',
                                                            height: '90px',
                                                            borderRadius: '10%',
                                                        }}
                                                        src={item.image}
                                                        alt={item.image}
                                                        fluid
                                                        rounded
                                                    />
                                                </TableCell>
                                                <TableCell variant="body" sx={{ fontSize: 15 }} color="text.secondary">
                                                    {item?.name}
                                                </TableCell>
                                                <TableCell variant="body" sx={{ fontSize: 15, textAlign: 'center' }} color="text.secondary">
                                                    {item?.quantity}
                                                </TableCell>
                                                <TableCell variant="body" sx={{ fontSize: 15, textAlign: 'center' }} color="text.secondary">
                                                    {item?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                </TableCell>
                                                <TableCell variant="body" sx={{ fontSize: 15, textAlign: 'center' }} color="text.secondary">
                                                    {itemTotal?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} {/* Display the sum for the row */}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </div>

                        <Card sx={{ minWidth: 275 }} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                            {paymentMethod === 'COD' && (
                                <CardContent>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontSize: 24, fontStyle: 'italic' }} colSpan={2}>
                                                    Order Cost Summary
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell variant="body" sx={{ fontSize: 15 }} color="text.secondary">
                                                    Shipping Cost:
                                                </TableCell>
                                                <TableCell variant="body" sx={{ fontSize: 15, color: '#004b67' }}>
                                                    Shs {serverShippingFees?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell variant="body" sx={{ fontSize: 15 }} color="text.secondary">
                                                    <b>Total:</b>
                                                </TableCell>
                                                <TableCell variant="body" sx={{ fontSize: 15, color: '#004b67' }}>
                                                    Shs {totalOrderCostPlusShipping?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>

                                    <Divider style={{ paddingTop: '10px', marginBottom: '10px' }} />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={placeOrderHandler}
                                        style={{
                                            width: '100%',
                                        }}
                                        disabled={orderCreationStatus === 'pending'}
                                    >
                                        {orderCreationStatus === 'pending' ? (
                                            <>
                                                <CircularProgress size={24} style={{ marginRight: '8px' }} />
                                                Processing...
                                            </>
                                        ) : (
                                            'Place Order'
                                        )}
                                    </Button>
                                </CardContent>
                            )}

                            {paymentMethod === 'Visa' && (
                                <CardContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div
                                        style={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '300px',
                                            height: '300px',
                                        }}
                                    ></div>
                                    <div style={{ flexGrow: 1 }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: 24, fontStyle: 'italic' }} colSpan={2}>
                                                        Order Cost Summary Visa
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        variant="body"
                                                        sx={{
                                                            fontSize: 15,
                                                            width: '20%',
                                                            color: 'text.secondary',
                                                        }}
                                                    >
                                                        <b>Shipping Cost:</b>
                                                    </TableCell>
                                                    <TableCell variant="body" sx={{ fontSize: 15, color: '#004b67' }}>
                                                        Shs {serverShippingFees?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        variant="body"
                                                        sx={{
                                                            fontSize: 15,
                                                            width: '20%',
                                                            color: 'text.secondary',
                                                        }}
                                                    >
                                                        <b>Total:</b>
                                                    </TableCell>
                                                    <TableCell variant="body" sx={{ fontSize: 15, color: '#004b67' }}>
                                                        Shs {totalOrderCostPlusShipping?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                    <div className={classes.responsiveCard}>
                                        <CreditCardForm
                                            onFormChange={(data) => {
                                                let expiry_month, expiry_year;
                                                if (typeof data.expirationDate === 'string') {
                                                    [expiry_month, expiry_year] = data.expirationDate.split('/');
                                                } else {
                                                    expiry_month = null;
                                                    expiry_year = null;
                                                }

                                                formik.setValues({
                                                    cardNumber: data.cardNumber,
                                                    expiry_month,
                                                    expiry_year,
                                                    cardCvv: data.cvv,
                                                    cardType: data.cardType,
                                                    fullName: data.fullName,
                                                });
                                            }}
                                            values={formik.values}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            handleBlur={formik.handleBlur}
                                            handleChange={formik.handleChange}
                                        />
                                        <Divider style={{ paddingTop: '10px', marginBottom: '10px' }} />
                                        <PayCardButton
                                            variant="contained"
                                            color="primary"
                                            onClick={formik.submitForm}
                                            disabled={
                                                paymentCreationStatus === 'pending' ||
                                                orderCreationStatus === 'pending' ||
                                                !formik.isValid
                                            }
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            {paymentCreationStatus === 'pending' || orderCreationStatus === 'pending'
                                                ? 'Processing...'
                                                : 'Pay'}
                                        </PayCardButton>
                                    </div>

                                    <Divider style={{ paddingTop: '10px', marginBottom: '10px' }} />
                                </CardContent>
                            )}

                            {paymentMethod === 'MOMO' && (
                                <CardContent style={{ flex: '3', display: 'flex', flexDirection: 'column' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontSize: 24, fontStyle: 'italic' }} colSpan={2}>
                                                    Order Cost Summary
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    variant="body"
                                                    sx={{
                                                        fontSize: 15,
                                                        width: '20%',
                                                        color: 'text.secondary',
                                                    }}
                                                >
                                                    <b>Shipping Cost:</b>
                                                </TableCell>
                                                <TableCell variant="body" sx={{ fontSize: 15, color: '#004b67' }}>
                                                    Shs {serverShippingFees?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    variant="body"
                                                    sx={{
                                                        fontSize: 15,
                                                        width: '20%',
                                                        color: 'text.secondary',
                                                    }}
                                                >
                                                    <b>Total:</b>
                                                </TableCell>
                                                <TableCell variant="body" sx={{ fontSize: 15, color: '#004b67' }}>
                                                    Shs {totalOrderCostPlusShipping?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>

                                    <div style={{ paddingBottom: '20px', paddingTop: '10px' }}>
                                        <Box
                                            sx={{
                                                bgcolor: theme.palette.background.paper,
                                                boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, .3)',
                                                p: 1,
                                                borderRadius: 2,
                                                width: { xs: '100%', sm: '100%', md: '28%' },
                                                margin: '0px 0px 4px 0px',
                                            }}
                                        >
                                            <StyledTextField
                                                className={`${classes.input} ${postData?.mobile?.length === 10 ? classes.inputGreen : ''}`}
                                                id="outlined-basic"
                                                label="Enter MTN Number"
                                                variant="outlined"
                                                type="number"
                                                color="secondary"
                                                value={postData?.mobile}
                                                onChange={(e) => {
                                                    if (e.target.value.length <= 10) {
                                                        setPostData({
                                                            ...postData,
                                                            mobile: e.target.value,
                                                        });
                                                    }
                                                }}
                                                inputProps={{
                                                    maxLength: 10,
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                fullWidth
                                                name="mobile"
                                                placeholder="Enter your MTN Number..."
                                                required
                                            />
                                        </Box>
                                    </div>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={payMoMoCheckoutHandler}
                                        disabled={postData.mobile.length < 10}
                                    >
                                        <img
                                            style={{
                                                width: 65,
                                                height: 50,
                                                borderRadius: 5,
                                                alignSelf: 'left',
                                                paddingRight: '10px',
                                            }}
                                            src={momo}
                                            alt="mtn-logo"
                                        />
                                        Proceed to Pay
                                    </Button>
                                </CardContent>
                            )}

                            {paymentMethod === 'Airtel' && (
                                <CardContent style={{ flex: '3', display: 'flex', flexDirection: 'column' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontSize: 24, fontStyle: 'italic' }} colSpan={2}>
                                                    Order Cost Summary
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    variant="body"
                                                    sx={{
                                                        fontSize: 15,
                                                        width: '20%',
                                                        color: 'text.secondary',
                                                    }}
                                                >
                                                    <b>Shipping Cost:</b>
                                                </TableCell>
                                                <TableCell variant="body" sx={{ fontSize: 15, color: '#004b67' }}>
                                                    Shs {serverShippingFees?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    variant="body"
                                                    sx={{
                                                        fontSize: 15,
                                                        width: '20%',
                                                        color: 'text.secondary',
                                                    }}
                                                >
                                                    <b>Total:</b>
                                                </TableCell>
                                                <TableCell variant="body" sx={{ fontSize: 15, color: '#004b67' }}>
                                                    Shs {totalOrderCostPlusShipping?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>

                                    <div style={{ paddingBottom: '20px', paddingTop: '10px' }}>
                                        <Box
                                            sx={{
                                                bgcolor: theme.palette.background.paper,
                                                boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, .3)',
                                                p: 1,
                                                borderRadius: 2,
                                                width: { xs: '100%', sm: '100%', md: '28%' },
                                                margin: '0px 0px 4px 0px',
                                            }}
                                        >
                                            <StyledTextField
                                                className={`${classes.input} ${postData?.mobile?.length === 10 ? classes.inputGreen : ''}`}
                                                id="outlined-basic"
                                                label="Enter Airtel Number"
                                                variant="outlined"
                                                type="number"
                                                color="secondary"
                                                value={postData?.mobile}
                                                onChange={(e) => {
                                                    if (e.target.value.length <= 10) {
                                                        setPostData({
                                                            ...postData,
                                                            mobile: e.target.value,
                                                        });
                                                    }
                                                }}
                                                inputProps={{
                                                    maxLength: 10,
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                fullWidth
                                                name="mobile"
                                                placeholder="Enter your Airtel Number..."
                                                required
                                            />
                                        </Box>
                                    </div>

                                    <AirtelPayButton
                                        variant="contained"
                                        color="primary"
                                        onClick={payAirtelMoneyCheckoutHandler}
                                        disabled={
                                            postData.mobile.length < 10 ||
                                            orderCreationStatus === 'pending' ||
                                            paymentCreationStatus === 'pending' ||
                                            paymentCreationStatus === 'processing payment' ||
                                            paymentCreationStatus === 'fulfilled' ||
                                            orderCreationStatus === 'fulfilled'
                                        }
                                    >
                                        <img
                                            style={{
                                                width: 65,
                                                height: 55,
                                                borderRadius: 5,
                                                alignSelf: 'left',
                                                boxShadow: '0px 0px 10px rgba(255, 255, 255, 0.9)',
                                            }}
                                            src={airtel}
                                            alt="airtel-logo"
                                        />
                                        <span style={{ fontSize: '16px', fontWeight: 'bold', color: 'white', paddingLeft: '11px' }}>
                                            {airtelButtonText}
                                        </span>
                                    </AirtelPayButton>
                                </CardContent>
                            )}
                        </Card>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
};

export default RestaurantPlaceOrderScreen;
